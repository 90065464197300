<template>
  <div class="main-container">
    <b-card class="card shadow">
      <div style="text-align: center">
        <img
          src="@/assets/mts-logo.png"
          alt="logo"
          class="logo-login mb-5 mt-2"
        />
      </div>

      <div v-if="isLoading" id="spinner" class="align-middle">
        <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
      </div>
      <div v-else-if="!isLoading && gotMessageFromServer">
        <b-card-text style="font-weight: bold">{{isAGoodMessage ? 'Buone notizie!' : 'Ops!'}}</b-card-text> 
        <b-card-text>
          {{isAGoodMessage ? "Ti abbiamo inviato un link per generare una nuova password all' indirizzo email che ci hai fornito." : 'Errore imprevisto. Riprovare più tardi.'}}

        </b-card-text>
        <b-button
          variant="primary"
          id="return-to-login-button"
          @click="$router.push({ name: 'login' })"
          >Torna al login</b-button
        >
      </div>
      <div v-else>
        <b-card-text style="font-weight: bold">Resetta password</b-card-text> 
        <b-card-text>
          Iserisci il tuo indirizzo email per avviare la procedura di recupero password
        </b-card-text>
        <b-form-group id="email-field" label="Email" label-for="email-field">
          <b-form-input
            id="email"
            placeholder="Scrivi il tuo indirizzo email"
            :state="emailError"
            v-model="email"
            trim
          ></b-form-input>
        </b-form-group>

        <b-button variant="primary" id="reset-password-button" @click="submit()"
          >Resetta password</b-button
        >
    </div>
      <router-link to="/login">Torna al log in</router-link>
    </b-card>
  </div>
</template>

<script>
import {changePasswordTokens} from "@/services/APIcalls.js";
import md5 from 'md5';

export default {
  name: "reset-password-component",
  methods: {
    validateEmail (email) {
      // eslint-disable-next-line
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    submit() {
      if(!this.validateEmail(this.email)) {
        this.emailError = false;
        return;
      }
      this.emailError = true;
      this.isLoading = true;
      changePasswordTokens(this.emailMd5)
      .then( () => {
        this.isAGoodMessage = true;
      })
      .catch( () => {
        this.isAGoodMessage = false;
      })
      .finally(()=> {
        this.isLoading = false;
        this.gotMessageFromServer = true;
      } )
    },
  },
  data() {
    return {
      email: "",
      emailError : null,
      isLoading: false,
      gotMessageFromServer: false,
      isAGoodMessage: false,
    };
  },
  computed: {
    emailMd5 (){
      return md5(this.email.toString().toLowerCase());
    }
  }
};
</script>

<style scoped>
.card {
  max-width: 400px;
  max-height: 532px;
  border-style: none;
  padding: 0 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

#return-to-login-button{
  position: absolute;
  right: 24px;
  bottom: 24px;
  width: fit-content;
}

#reset-password-button {
  position: absolute;
  right: 24px;
  bottom: 24px;
  width: fit-content;
}

.form-group {
  padding-bottom: 20px;
}
.btn {
  width: 150px;
}

a {
  position: absolute;
  bottom: -42px;
  left: 50%;
  transform: translateX(-50%);
  padding: 14px;
}

#spinner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%);
}
</style>
